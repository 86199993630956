import React, { createContext, useContext, useState, ReactNode } from 'react';
import { adminLoginAPI } from '../utils/apis';
import { useNavigate } from 'react-router-dom';

interface AuthContextType {
  user: any; // Replace 'any' with your user type
  login: (userData: any) => any;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const useAuth = () => useContext(AuthContext) as AuthContextType;

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<any>(null); // Replace 'any' with your user type
  
  const login = (userData: any) => {
    return new Promise((resolve, reject) => {
      adminLoginAPI(userData)
        .then((res) => {
          setUser(res.body?.userInfo);
          localStorage.setItem('userInfo', JSON.stringify(res.body?.userInfo));
          localStorage.setItem('token', res.body?.token);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('token')
    localStorage.removeItem('userInfo')
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
