import { Button } from '../../../components/BaseComponents';
import { useNavigate } from 'react-router-dom';

export default function Header(): JSX.Element {

    const navigate = useNavigate();
    const hanldeLogIn = () => navigate('/login');
    const handleSignUp = () => navigate('/signup');

    return (
        <nav
            className="flex-no-wrap relative flex w-full items-center justify-between bg-[#FBFBFB] py-2 shadow-md shadow-black/5 dark:bg-neutral-600 dark:shadow-black/10 lg:flex-wrap lg:justify-start lg:py-4">
            <div className="flex w-full flex-wrap items-center justify-between px-3">
                <button
                    className="block border-0 bg-transparent px-2 text-neutral-500 hover:no-underline hover:shadow-none focus:no-underline focus:shadow-none focus:outline-none focus:ring-0 dark:text-neutral-200 lg:hidden"
                    type="button"
                    data-te-collapse-init
                    data-te-target="#navbarSupportedContent1"
                    aria-controls="navbarSupportedContent1"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span className="[&>svg]:w-7">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="h-7 w-7">
                            <path
                                fill-rule="evenodd"
                                d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                                clip-rule="evenodd" />
                        </svg>
                    </span>
                </button>

                <div
                    className="!visible hidden flex-grow basis-[100%] items-center lg:!flex lg:basis-auto"
                    id="navbarSupportedContent1"
                    data-te-collapse-item>
                    <ul
                        className="list-style-none mr-auto flex md:flex-col pl-0 lg:flex-row"
                        data-te-navbar-nav-ref>
                        <li className="mb-4 lg:mb-0 lg:pr-2" data-te-nav-item-ref>
                            <a
                                className="text-neutral-500 transition duration-200 hover:text-neutral-700 hover:ease-in-out focus:text-neutral-700 disabled:text-black/30 motion-reduce:transition-none dark:text-neutral-200 dark:hover:text-neutral-300 dark:focus:text-neutral-300 lg:px-2 [&.active]:text-black/90 dark:[&.active]:text-zinc-400"
                                href="#"
                                data-te-nav-link-ref
                            >Home</a>
                        </li>
                        <li className="mb-4 lg:mb-0 lg:pr-2" data-te-nav-item-ref>
                            <a
                                className="text-neutral-500 transition duration-200 hover:text-neutral-700 hover:ease-in-out focus:text-neutral-700 disabled:text-black/30 motion-reduce:transition-none dark:text-neutral-200 dark:hover:text-neutral-300 dark:focus:text-neutral-300 lg:px-2 [&.active]:text-black/90 dark:[&.active]:text-neutral-400"
                                href="#surveys"
                                data-te-nav-link-ref
                            >Surveys</a>
                        </li>
                        <li className="mb-4 lg:mb-0 lg:pr-2" data-te-nav-item-ref>
                            <a
                                className="text-neutral-500 transition duration-200 hover:text-neutral-700 hover:ease-in-out focus:text-neutral-700 disabled:text-black/30 motion-reduce:transition-none dark:text-neutral-200 dark:hover:text-neutral-300 dark:focus:text-neutral-300 lg:px-2 [&.active]:text-black/90 dark:[&.active]:text-neutral-400"
                                href="#features"
                                data-te-nav-link-ref
                            >Features</a>
                        </li>
                        <li className="mb-4 lg:mb-0 lg:pr-2" data-te-nav-item-ref>
                            <a
                                className="text-neutral-500 transition duration-200 hover:text-neutral-700 hover:ease-in-out focus:text-neutral-700 disabled:text-black/30 motion-reduce:transition-none dark:text-neutral-200 dark:hover:text-neutral-300 dark:focus:text-neutral-300 lg:px-2 [&.active]:text-black/90 dark:[&.active]:text-neutral-400"
                                href="#aboutus"
                                data-te-nav-link-ref
                            >About Us</a>
                        </li>
                    </ul>
                </div>
                <div className="relative flex items-center">
                    <div className="flex justify-center items-center">
                        <Button
                            color='danger'
                            className="inline-block rounded px-6 pb-2 pt-2.5 text-xs font-medium me-3"
                            onClick={hanldeLogIn}>
                            Log In
                        </Button>
                        <Button
                            color='warning'
                            className="inline-block rounded px-6 pb-2 pt-2.5 text-xs font-medium"
                            onClick={handleSignUp}
                        >
                            Create Account
                        </Button>
                    </div>
                </div>
            </div>
        </nav>
    );
}