import { TEInput } from "tw-elements-react";
import { Button } from "../../components/BaseComponents";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useAuth } from "../../contexts/AuthProvider";

export default function Login(): JSX.Element {

    const { login } = useAuth();
    const navigate = useNavigate();

    const [userData, setUserData] = useState({
        userInfo: '',
        password: ''
    });

    const handleLogin = async () => {
        const result = await login(userData);
        if (result?.status == 'Ok') {
            navigate('/dashboard');
        }
    }

    return (
        <section className="h-screen">
            <div className="h-full">

                {/* <!-- Left column container with background--> */}
                <div className="g-6 flex h-full flex-wrap items-center justify-center lg:justify-between">
                    <div className="shrink-1 mb-12 grow-0 basis-auto md:mb-0 md:w-9/12 md:shrink-0 lg:w-6/12 xl:w-6/12">
                        <img
                            src="https://tecdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
                            className="w-full"
                            alt="Sample image"
                        />
                    </div>

                    {/* <!-- Right column container --> */}
                    <div className="mb-12 md:mb-0 md:w-8/12 lg:w-3/12 xl:w-5/12 me-10">
                        <form>

                            {/* <!-- Email input --> */}
                            <TEInput
                                type="email"
                                label="Email address"
                                size="lg"
                                className="mb-6"
                                value={userData.userInfo.toLowerCase()}
                                onChange={(e) => setUserData({ ...userData, userInfo: e.target.value })}
                            ></TEInput>

                            {/* <!--Password input--> */}
                            <TEInput
                                type="password"
                                label="Password"
                                className="mb-6"
                                size="lg"
                                value={userData.password}
                                onChange={(e) => setUserData({ ...userData, password: e.target.value })}
                            ></TEInput>

                            {/* <!-- Login button --> */}
                            <div className="text-center lg:text-left">
                                <Button
                                    color="danger"
                                    className="inline-block rounded px-7 pb-2.5 pt-3 text-sm font-medium"
                                    onClick={handleLogin}
                                >{"Log In"}</Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}