import Footer from "./components/Footer";
import Header from "./components/Header";
import Section1 from "./components/Section1";

export default function LandingPage() {

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col gap-[30px]">
            <Header />
            <Section1 />
            <Footer />
        </div>
    );
}