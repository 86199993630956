import { useState } from 'react';
import { TEInput } from "tw-elements-react";
import { Button } from "../../components/BaseComponents";
import './styles.css';
import { sendSMSAPI } from '../../utils/apis';

export default function Main(): JSX.Element {
    const [phoneNumbers, setPhoneNumbers] = useState<Array<string>>([]);
    const [phoneNumberInput, setPhoneNumberInput] = useState('');
    const [smsText, setSmsText] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const isValidPhoneNumber = (number: string) => {
        return /^(\+234)?\d{10,11}$/.test(number);
    };

    const formatPhoneNumber = (number: string) => {
        if (number.startsWith('+2340')) {
            return '+234' + number.substring(5);
        } else if (number.startsWith('+234')) {
            return number;
        } else if (number.startsWith('0')) {
            return '+234' + number.substring(1);
        } else {
            return '+234' + number;
        }
    };

    const handleAddPhoneNumber = () => {
        const newNumbers = phoneNumberInput.split(',')
            .map(num => num.trim())
            .map(num => isValidPhoneNumber(num) ? formatPhoneNumber(num) : num);

        const combinedNumbers = new Set([...phoneNumbers, ...newNumbers]);
        setPhoneNumbers(Array.from(combinedNumbers));
        setPhoneNumberInput('');
    };

    const handleRemovePhoneNumber = (numberToRemove: string) => {
        setPhoneNumbers(phoneNumbers.filter(number => number !== numberToRemove));
    };

    const handleClearPhoneNumber = () => {
        setPhoneNumbers([]);
    }

    const handleSendSMS = () => {
        if (phoneNumbers.length == 0 || !smsText) return;
        setIsLoading(true);
        sendSMSAPI({ phoneNumbers, text: smsText }).then(() => {
            console.log('Sent SMS: ' + smsText);
            setPhoneNumbers([]);
            setSmsText('');
            setIsLoading(false);
        }).catch((error) => {
            console.log(error);
            setIsLoading(false);
        });
    }

    return (
        <section className="h-screen">
            <div className="h-full">
                <div className='flex items-center justify-center m-10'>
                    <h1 className='text-title' >Customer Mail System</h1>
                </div>
                <div className="m-4 g-6 flex items-center justify-center">
                    <div className="mb-12 md:mb-0 md:w-8/12 lg:w-12/12 me-10">
                        <label htmlFor="smsText" className="form-label inline-block mb-2 text-gray-700 text-xl">Input Customer Mobile Number Lists</label>
                        <TEInput
                            type="tel"
                            label="Customer Phone Number Text"
                            size="lg"
                            className="mb-3"
                            placeholder="Enter customer phone numbers... (e.g. 8033919555, 08033919556, +2348033919554)"
                            value={phoneNumberInput}
                            onChange={(e) => setPhoneNumberInput(e.target.value)}
                        ></TEInput>

                        <div className="lg:text-right mb-3">
                            <Button
                                color="warning"
                                className="inline-block rounded px-4 py-1.5 text-sm font-medium me-3"
                                onClick={handleClearPhoneNumber}
                            >{"Clear"}</Button>
                            <Button
                                color="info"
                                className="inline-block rounded px-4 py-1.5 text-sm font-medium"
                                onClick={handleAddPhoneNumber}
                            >{"+ Add"}</Button>
                        </div>

                        {/* Phone number tags */}
                        <div className="mb-6">
                            {phoneNumbers.map((number, index) => (
                                <span key={index} className={`tag inline-flex items-center rounded-full px-3 py-1 text-sm font-semibold mr-2 mb-2 cursor-pointer ${isValidPhoneNumber(number) ? 'bg-green-200 text-green-700 hover:bg-green-300' : 'bg-red-200 text-red-700 hover:bg-red-300'}`}>
                                    {number}
                                    <button className="ml-2 tag" onClick={() => handleRemovePhoneNumber(number)}>×</button>
                                </span>
                            ))}
                        </div>

                        {/* SMS Text Area */}
                        <div className="mb-6">
                            <label htmlFor="smsText" className="form-label inline-block mb-2 text-gray-700 text-xl">SMS Message</label>
                            <textarea
                                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none h-[300px]"
                                id="smsText"
                                placeholder="Type your message here"
                                value={smsText}
                                onChange={(event) => setSmsText(event.target.value)}
                            ></textarea>
                        </div>

                        {/* Send Button */}
                        <div className="text-right lg:text-right">
                            <Button
                                color="success"
                                className="inline-block rounded px-7 pb-2.5 pt-3 text-sm font-medium min-w-[120px]"
                                onClick={handleSendSMS}
                            >
                                {isLoading ?
                                    <div className="inline-block h-4 w-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite] me-2" role="status">
                                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">{'Loading...'}</span>
                                    </div> :
                                    <div>{"Send SMS"}</div>
                                }
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
