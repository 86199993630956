
export default function Section1(): JSX.Element {
    return (
        <div>
            <h1 className="m-2 text-5xl font-medium leading-tight">
                {'Example heading'}
                <span
                    className="inline-block whitespace-nowrap rounded-[0.27rem] bg-primary-100 px-[0.65em] pb-[0.25em] pt-[0.35em] text-center align-baseline text-[0.75em] font-bold leading-none text-primary-700"
                >{'New'}</span>
            </h1>
            <div className="container p-6 mx-auto" id="features">
                <div className="grid gap-4 md:grid-cols-3 lg:grid-cols-3">
                    <div className="mb-6 lg:mb-0">
                        <img
                            alt="img-1"
                            src="https://tecdn.b-cdn.net/img/new/fluid/city/113.webp"
                            className="w-full rounded-md shadow-lg" />
                    </div>
                    <div className="mb-6 lg:mb-0">
                        <img
                            alt="img-2"
                            src="https://tecdn.b-cdn.net/img/new/fluid/city/111.webp"
                            className="w-full rounded-md shadow-lg" />
                    </div>
                    <div className="mb-6 lg:mb-0">
                        <img
                            alt="img-3"
                            src="https://tecdn.b-cdn.net/img/new/fluid/city/112.webp"
                            className="w-full rounded-md shadow-lg" />
                    </div>
                    <div className="mb-6 lg:mb-0">
                        <img
                            alt="img-4"
                            src="https://tecdn.b-cdn.net/img/new/fluid/city/114.webp"
                            className="w-full rounded-md shadow-lg" />
                    </div>
                    <div className="mb-6 lg:mb-0">
                        <img
                            alt="img-5"
                            src="https://tecdn.b-cdn.net/img/new/fluid/city/115.webp"
                            className="w-full rounded-md shadow-lg" />
                    </div>
                    <div className="mb-6 lg:mb-0">
                        <img
                            alt="img-6"
                            src="https://tecdn.b-cdn.net/img/new/fluid/city/116.webp"
                            className="w-full rounded-md shadow-lg" />
                    </div>
                </div>
            </div>
        </div>
    );
}