import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthProvider';

export const PublicRoute = ({ component: Component, ...rest }) => (
    <Component {...rest} />
);

export const PrivateRoute = ({ component: Component, ...rest }) => {
    const { user } = useAuth();
    return (user ? <Component {...rest} /> : <Navigate to="/login" />);
};

export const CommonRoute = ({ component: Component, ...rest }) => {
    const { user } = useAuth();
    return (!user ? <Component {...rest} /> : <Navigate to="/dashboard" />);
};
