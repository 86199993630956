import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { PublicRoute, PrivateRoute, CommonRoute } from './routes';
import { AuthProvider } from './contexts/AuthProvider';
import LandingPage from './pages/landing';
import LogIn from './pages/auth/Login';
import SignUpPage from './pages/auth/Signup';
import Main from './pages/dashboard/Main';

const App: React.FC = () => {
  return (
    <AuthProvider>
      <Router basename='/'>
        <Routes>
          <Route path="/" element={<PublicRoute component={LogIn} />} />
          <Route path="/login" element={<PublicRoute component={LogIn} />} />
          <Route path="/signup" element={<PublicRoute component={SignUpPage} />} />
          <Route path="/dashboard" element={<PrivateRoute component={Main} />} />
          <Route path="/common" element={<CommonRoute component={LandingPage} />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
